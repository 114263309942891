<template>
  <div>
    <v-dialog
      v-model="addDialogue"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Add New Stock</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="ecoshopinputlabel"> Size </span>
                    <v-select
                      v-if="!addSizeDialogue"
                      :disabled="addSizeDialogue ? false : true"
                      outlined
                      dense
                      color="#30B868"
                      placeholder="Size"
                      v-model="selectedStock.size"
                      :items="sizes"
                      item-text="value"
                      item-value="value"
                      item-color="#30B868"
                      hide-details
                      class="text-left"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item }}
                        </span>
                      </template>
                    </v-select>
                    <v-select
                      v-if="addSizeDialogue"
                      outlined
                      dense
                      color="#30B868"
                      placeholder="Size"
                      v-model="newSize"
                      item-disabled="disabled"
                      :items="sizes"
                      item-text="value"
                      item-value="value"
                      item-color="#30B868"
                      hide-details
                      class="text-left"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item.value }}
                        </span>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left v-if="addSizeDialogue">
                    <span class="ecoshopinputlabel"> Color Available </span>
                    <v-switch
                      v-model="colorStatus"
                      color="#5DC57C"
                      hide-details
                      :ripple="false"
                      class="statusKey pt-2"
                    >
                      <template slot="label">
                        <span
                          style="
                            color: #000000;
                            font-family: opensanssemibold;
                            font-size: 16px;
                          "
                          v-if="colorStatus"
                        >
                          Yes
                        </span>
                        <span
                          style="
                            color: #000000;
                            font-family: opensanssemibold;
                            font-size: 16px;
                          "
                          v-else
                        >
                          No
                        </span>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex v-if="colorStatus" xs6 sm4 px-2 text-left>
                    <span class="ecoshopinputlabel"> color </span>
                    <br />
                    <input
                      style="width: 100%; height: 40px"
                      type="color"
                      id="newColor"
                      name="newColor"
                      value="#000000"
                    />
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="ecoshopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="newStock"
                      type="number"
                      color="#30B868"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="addDialogue = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      v-if="!addSizeDialogue"
                      tile
                      block
                      dark
                      color="#30B868"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="addStock"
                    >
                      Apply
                    </v-btn>
                    <v-btn
                      v-if="addSizeDialogue"
                      tile
                      block
                      dark
                      color="#30B868"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="addSizeStock"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <template v-for="(item, i) in productStock">
      <v-layout wrap justify-start :key="i">
        <v-flex xs6 sm2 pa-2>
          <v-card color="#000000" height="50px" flat>
            <v-layout wrap justify-center fill-height>
              <v-flex xs4 align-self-center>
                <span
                  style="
                    font-family: opensansbold;
                    font-size: 14px;
                    color: #ffffff;
                  "
                >
                  Size
                </span>
              </v-flex>
              <v-flex xs4 align-self-center>
                <span
                  style="
                    font-family: opensansbold;
                    font-size: 14px;
                    color: #ffffff;
                  "
                >
                  {{ item.size }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 sm10 pl-2>
          <v-layout wrap justify-start>
            <v-flex xs6 sm2 v-for="(item2, i) in item.stock" :key="i" pa-2>
              <v-card outlined flat height="50px">
                <v-layout wrap justify-center>
                  <v-flex xs6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: opensanslight;
                            font-size: 14px;
                            color: #848484;
                          "
                        >
                          Color
                        </span>
                      </v-flex>
                      <v-flex xs12 text-center align-self-center>
                        <v-avatar :color="item2.color" size="15"></v-avatar>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: opensanslight;
                            font-size: 14px;
                            color: #65ce96;
                          "
                        >
                          Stock
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: opensansbold;
                            font-size: 14px;
                            color: #000000;
                          "
                        >
                          {{ item2.stock }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
  </div>
</template>
<script>
export default {
  props: ["storage", "sizeData"],
  data() {
    return {
      addDialogue: false,
      addSizeDialogue: false,
      newSize: null,
      newStock: 0,
      colorStatus: false,
      newColor: null,
      productStock: [],
      selectedStock: {},
      sizes: [],
    };
  },
  mounted() {
    if (this.sizeData) this.productStock = this.sizeData;
    this.sizes = [];
    if (this.storage) {
      for (var i = 0; i < this.storage.length; i++) {
        var obj = {};
        obj.value = this.storage[i];
        obj.disabled = false;
        this.sizes.push(obj);
      }
    }
  },
  watch: {
    productStock: {
      handler: function () {
        this.$emit("stepper", {
          productStock: this.productStock,
        });
      },
      deep: true,
    },
  },

  methods: {
    stockAdder(item) {
      if (item == "new") {
        this.addSizeDialogue = true;
      } else {
        this.addSizeDialogue = false;
        this.selectedStock = item;
      }
      this.addDialogue = true;
    },
    addStock() {
      if (!this.newStock) return;

      if (document.getElementById("newColor"))
        this.newColor = document.getElementById("newColor").value;
      var obj = {};
      obj.color = this.newColor;
      obj.stock = Number(this.newStock);
      for (var i = 0; i < this.productStock.length; i++) {
        if (this.selectedStock.size == this.productStock[i].size) {
          this.productStock[i].stock.push(obj);
        }
      }
      this.newSize = null;
      this.newColor = null;
      this.newStock = null;
      this.addDialogue = false;
      this.$emit("stepper", {
        productStock: this.productStock,
      });
    },
    addSizeStock() {
      if (!this.newSize) return;

      if (document.getElementById("newColor"))
        this.newColor = document.getElementById("newColor").value;
      var obj = {};
      var obj2 = {};
      obj.stock = [];
      obj2.color = this.newColor;
      obj2.stock = Number(this.newStock);
      obj.size = this.newSize;
      obj.color = this.colorStatus;
      obj.stock.push(obj2);
      this.productStock.push(obj);
      this.newSize = null;
      this.newColor = null;
      this.newStock = null;
      this.addSizeDialogue = false;
      this.addDialogue = false;
      this.$emit("stepper", {
        productStock: this.productStock,
      });
    },
  },
};
</script>
<style >
.stockSizeSelector.v-text-field.v-text-field--solo .v-input__control {
  min-height: 50px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
</style>