<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-start px-2 px-lg-0 pl-lg-12 pl-xl-12>
      <v-flex xs12 py-4>
        <v-layout pt-3 wrap justify-start>
          <v-flex xs7 sm3 md3 lg2 xl2>
            <v-layout wrap justify-center>
              <v-flex xs3 sm2 text-left align-self-center>
                <v-img
                  src="./../../../assets/greenlogo.png"
                  contain
                  height="15px"
                  alt="STYLOOP"
                ></v-img>
              </v-flex>
              <v-flex xs9 sm10 text-left pl-2 align-self-center>
                <span
                  style="
                    font-family: poppinssemibold;
                    font-size: 18px;
                    color: #1c1c1c;
                  "
                >
                  Add / Edit Deal
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- Main Container -->
      <v-flex xs12 md12 lg7 xl7>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 md4 lg4 xl4 pr-sm-2 text-left>
            <span class="ecoshopinputlabel"> Select item type </span>
            <v-select
              disabled
              outlined
              dense
              color="#30B868"
              placeholder="Sub-category"
              v-model="product.subcategory"
              :items="subcategoryArray"
              item-text="name"
              item-value="_id"
              item-color="#30B868"
              hide-details
              v-on:input="subSize(product.subcategory)"
              class="text-left"
            >
              <template v-slot:item="{ item }">
                <span class="text-left">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12 sm8 md8 lg8 xl8 pl-sm-2 text-left>
            <span class="ecoshopinputlabel"> Select Category type </span>
            <v-select
              disabled
              outlined
              small-chips
              deletable-chips
              dense
              multiple
              v-model="product.category"
              color="#30B868"
              placeholder="Category"
              :items="categoryArray"
              item-text="name"
              item-value="_id"
              item-color="#30B868"
              hide-details
            >
            </v-select>
          </v-flex>
          <v-flex xs12 text-left>
            <span class="ecoshopinputlabel"> Pincode </span>
            <v-autocomplete
              disabled
              outlined
              multiple
              dense
              color="#30B868"
              deletable-chips
              placeholder="Pincode"
              v-model="product.pincode"
              :items="pincodeArray"
              item-color="#30B868"
              item-text="Pincode"
              hide-details
            >
              <template v-slot:selection="{ attrs, item, selected }">
                <span class="pa-1">
                  <v-chip v-bind="attrs" :input-value="selected" small>
                    <span style="font-family: opensansregular; font-size: 13px">
                      {{ item.Pincode }}
                    </span>
                  </v-chip>
                </span>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs12 text-left>
            <span class="ecoshopinputlabel"> Name of the Product </span>
            <v-text-field
              disabled
              v-model="product.productname"
              color="#30B868"
              hide-selected
              outlined
              placeholder="Name of the product"
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex xs12 text-left>
            <span class="ecoshopinputlabel"> Caption of the Product </span>
            <v-textarea
              disabled
              rows="2"
              v-model="product.caption"
              color="#30B868"
              hide-selected
              outlined
              placeholder="Enter a few words about the product"
              dense
              counter
              maxlength="100"
            ></v-textarea>
          </v-flex>
          <v-flex xs12 text-left>
            <span class="ecoshopinputlabel"> Description of the Product </span>
            <v-textarea
              disabled
              rows="3"
              v-model="product.description"
              color="#30B868"
              hide-selected
              outlined
              placeholder="Describe the product"
              dense
              counter
            ></v-textarea>
          </v-flex>
          <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel">Add Description</span>
            <v-combobox
              placeholder="Description Tags"
              v-model="product.tags"
              outlined
              :items="tagArray"
              color="#30B868"
              multiple
              dense
              hide-details
              small-chips
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <span class="pa-1">
                  <v-chip v-bind="attrs" :input-value="selected" small>
                    <span
                      class="pr-2"
                      style="font-family: opensansregular; font-size: 13px"
                    >
                      {{ item }} </span
                    >&nbsp;
                    <v-icon small @click="parent.selectItem(item)">
                      mdi-close-circle
                    </v-icon>
                  </v-chip>
                </span>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel">Material Used</span>
            <v-combobox
              placeholder="Tags"
              v-model="product.materialused"
              outlined
              color="#30B868"
              multiple
              :items="materialArray"
              dense
              hide-details
              small-chips
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <span class="pa-1">
                  <v-chip v-bind="attrs" :input-value="selected" small>
                    <span
                      class="pr-2"
                      style="font-family: opensansregular; font-size: 13px"
                    >
                      {{ item }} </span
                    >&nbsp;
                    <v-icon small @click="parent.selectItem(item)">
                      mdi-close-circle
                    </v-icon>
                  </v-chip>
                </span>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex xs12 text-left pt-2>
            <span class="ecoshopinputlabel">Add to Deal</span>
            <v-select
              placeholder="Select Deal"
              color="#30B868"
              v-model="product.deal"
              :items="deals"
              item-color="#30B868"
              outlined
              dense
              clearable
              hint="You need min 3 products in deal to appear in listing"
              persistent-hint
            >
              <template v-slot:item="{ item }">
                <span class="text-left">{{ item }}</span>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- Image Container -->
      <v-flex xs12 md12 lg4 xl3 pl-sm-4 pt-2>
        <v-layout wrap justify-center>
          <v-flex pt-4 xs12 text-left>
            <v-layout wrap justify-center>
              <v-flex xs8 sm6 md6 lg8>
                <v-card flat tile outlined height="300px">
                  <v-layout wrap justify-center fill-height>
                    <v-flex md12 lg12 align-self-center>
                      <v-img
                        v-if="product.photos[0] || imageArray.length > 0"
                        :src="
                          product.photos[0]
                            ? mediaURL + product.photos[0]
                            : imageArray[0]
                        "
                        height="298px"
                        contain
                        alt="Photo"
                      >
                        <v-layout wrap>
                          <v-flex text-right pa-2> </v-flex>
                        </v-layout>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs4 sm3 md3 lg4>
                <v-layout pt-0 wrap justify-center>
                  <v-flex
                    xs12
                    v-for="(item, i) in product.photos"
                    :key="i"
                    pa-1
                  >
                    <v-img
                      v-if="i > 0"
                      :src="mediaURL + item"
                      height="85px"
                      contain
                      alt="Photos"
                    >
                      <v-layout wrap>
                        <v-flex text-right pa-0> </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                  <v-flex xs12 v-for="(item, i) in imageArray" :key="i" pa-1>
                    <v-img :src="item" alt="Photo" height="85px" contain>
                      <v-layout wrap>
                        <v-flex text-right pa-0>
                          <v-avatar color="#FF3434" size="15">
                            <v-icon
                              color="#FFF"
                              small
                              @click="removeImageArray(i)"
                            >
                              mdi-close
                            </v-icon>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- Product Stock -->
      <v-flex xs12 md12 lg10 xl7>
        <v-layout wrap justify-center>
          <v-flex xs12 text-left>
            <span class="ecoshopinputlabel">Product Stock</span>
          </v-flex>
          <v-flex xs12>
            <StockAdder
              v-bind:storage="subSizeArray"
              v-bind:sizeData="product.size"
              :key="subSizeArray[0]"
              @stepper="winStepper"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 md12 lg7 xl7>
        <v-layout pt-2 wrap>
          <v-flex sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel"> Total Stock </span>
            <v-text-field
              disabled
              type="number"
              placeholder="Stock"
              v-model="product.stockquantity"
              outlined
              color="#30B868"
              hide-details
              dense
            ></v-text-field>
          </v-flex>
          <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel"> Price </span>
            <v-text-field
              placeholder="Base Price"
              style="max-height: 46px !important"
              type="number"
              v-model="product.productcost"
              outlined
              color="#30B868"
              hide-details
              dense
            ></v-text-field>
          </v-flex>
          <!-- <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Markup Price</span>
            <v-text-field
              disabled
              placeholder="Markup Price"
              type="number"
              v-model="product.profitmargin"
              outlined
              color="#30B868"
              hide-details
              dense
            ></v-text-field>
          </v-flex> -->
          <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Total Price</span>
            <v-text-field
              disabled
              placeholder="Total Price"
              type="number"
              v-model="product.orginalprice"
              outlined
              color="#30B868"
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex sm3 md3 lg3 xl3 text-left pl-2>
            <span class="ecoshopinputlabel">Offer Percentage</span>
            <v-text-field
              disabled
              placeholder="Offer Percentage"
              type="number"
              v-model="product.offersetpercentage"
              outlined
              color="#30B868"
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Saved Amount</span>
            <v-text-field
              disabled
              placeholder="Saved Amount"
              type="number"
              v-model="product.savedAmount"
              outlined
              color="#30B868"
              dense
              hide-details
            ></v-text-field>
          </v-flex>
          <v-flex pl-sm-2 sm3 md3 lg3 xl3 text-left>
            <span class="ecoshopinputlabel">Offer Price</span>
            <v-text-field
              disabled
              placeholder="Offer Price"
              type="number"
              v-model="product.price"
              outlined
              color="#30B868"
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap py-4>
          <v-flex text-left xs12 lg12>
            <PriceDistro
              v-bind:productcost="product.productcost"
              v-bind:subcategoryData="singleSubcatData"
              v-bind:offer="product.offersetpercentage"
              :key="product.offersetpercentage"
              @stepper="priceChanger"
            />
          </v-flex>
        </v-layout>

        <!-- Text-input -->
      </v-flex>
      <v-flex md12 lg7 pb-3 py-4 text-right hidden-sm-and-down>
        <span class="px-2">
          <v-btn
            width="250px"
            outlined
            tile
            color="#757575"
            dark
            :ripple="false"
            depressed
            @click="$router.go(-1)"
          >
            <span
              style="
                font-family: kumbhsansregular;
                font-size: 16px;
                text-transform: none;
                color: #000000;
              "
            >
              Cancel
            </span>
          </v-btn>
        </span>
        <span class="px-2">
          <v-btn
            width="250px"
            tile
            dark
            color="#30B868"
            depressed
            class="text-capitalize"
            :ripple="false"
            @click="addDeal()"
          >
            Apply
          </v-btn>
        </span>
      </v-flex>
      <v-flex xs6 py-4 px-2 text-right hidden-md-and-up>
        <v-btn
          block
          outlined
          tile
          color="#757575"
          dark
          :ripple="false"
          depressed
          @click="$router.go(-1)"
        >
          <span
            style="
              font-family: kumbhsansregular;
              font-size: 16px;
              text-transform: none;
              color: #000000;
            "
          >
            Cancel
          </span>
        </v-btn>
      </v-flex>
      <v-flex xs6 py-4 px-2 hidden-md-and-up>
        <v-btn
          block
          tile
          dark
          color="#30B868"
          depressed
          class="text-capitalize"
          :ripple="false"
          @click="addDeal()"
        >
          Apply
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import StockAdder from "./stockAdder";
import PriceDistro from "./priceDistro";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    StockAdder,
    PriceDistro,
  },
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: null,
      ServerError: false,
      showSnackBar: false,
      imageArray: [],
      Images: new FormData(),
      formData: new FormData(),
      selectedFiles: null,
      image: null,
      pincodeArray: [],
      subSizeArray: [],
      productStock: {},
      product: {
        subcategory: null,
        category: [],
        pincode: [],
        productname: null,
        caption: null,
        description: null,
        stockquantity: null,
        productcost: null,
        // profitmargin: null,
        price: null,
        offer: null,
        offersetpercentage: null,
        savedAmount: null,
        instock: false,
        unit: "Count",
        photos: [],
        tags: [],
        materialused: [],
        size: [],
        deal: null,
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      imageCount: 0,
      deals: ["Buy 2 Get 1 Free", "Buy 3 above 5000 INR at 3000 INR"],
      singleSubcatData: {},
    };
  },
  computed: {
    categoryArray() {
      var arr = [];
      arr = this.$store.state.categories.data;
      arr = arr.splice(0, 2);
      return arr;
    },
    subcategoryArray() {
      return this.$store.state.subCategories;
    },
  },
  beforeMount() {
    this.$store.dispatch("getCategory");
    this.$store.dispatch("getSubCategory");
    this.getData();
    this.getPincode();
  },
  methods: {
    priceChanger(window_data) {
      this.product.price = window_data.price;
      this.product.savedAmount =
        Math.round((this.product.orginalprice - window_data.price) * 100) / 100;
    },
    winStepper(window_data) {
      this.productStock = window_data.productStock;
      this.product.size = this.productStock;
      this.product.stockquantity = 0;
      for (var i = 0; i < this.productStock.length; i++) {
        for (var j = 0; j < this.productStock[i].stock.length; j++) {
          this.product.stockquantity =
            this.product.stockquantity + this.productStock[i].stock[j].stock;
        }
      }
    },
    subSize(id) {
      for (var i = 0; i < this.subcategoryArray.length; i++) {
        if (id == this.subcategoryArray[i]._id) {
          this.subSizeArray = this.subcategoryArray[i].size;
          this.singleSubcatData = this.subcategoryArray[i];
        }
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/viewStore",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.product = response.data.data;
            this.product.deal = this.product.dealtype;
            this.subSize(this.product.subcategory._id);
            this.imageCount =
              this.product.photos.length + this.imageArray.length;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getPincode() {
      // this.appLoading = true;
      axios({
        url: "/loc/pincodelist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.pincodeArray = response.data.data;
          for (var i = 0; i < this.pincodeArray.length; i++) {
            this.pincodeArray[i].Pincode = Number(this.pincodeArray[i].Pincode);
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    addOffer() {
      this.appLoading = true;
      var product = {};
      product["id"] = this.product._id;
      product["offersetpercentage"] = Number(this.product.offersetpercentage);
      axios({
        method: "POST",
        url: "/Product/SetOffer",
        data: product,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.product.deal) {
              this.addDeal();
            } else {
              this.$router.push(
                "/Product/Deal Added/" + this.product._id
              );
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addDeal() {
      this.appLoading = true;
      var product = {};
      product["productid"] = this.product._id;
      product["dealtype"] = this.product.deal;
      axios({
        method: "POST",
        url: "/home/deal/add",
        data: product,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Product/Updated/" + this.product._id);
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style  scoped>
.v-input__slot {
  min-height: 36px;
}
.v-select > .dropdown-menu {
  text-align: right;
}
</style>